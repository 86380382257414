/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Col, Container, Row } from "reactstrap";

function About() {
  return (
    <>
      <div id="about" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger pb-2">
                The HGM Preaching Training Program aims to raise up and equip teachers of God’s Word.
                <br /><br />
                Participants in HGM Preaching Training will take part in a two-year program which involves three elements:
              </p>
              <p className="text-left">
                <ul>
                  <li>
                    3-4 half-day workshops per year,
                  </li>
                  <li>
                    Coaching, individually and in peer groups, from a seasoned Bible teacher; and
                  </li>
                  <li>
                    Practical experience preaching and giving Bible talks in real ministry contexts (not just in the “classroom”).
                  </li>
                </ul>
              </p>
              <img
                alt="Preaching Training"
                className="img-rounded img-responsive"
                src={require("assets/img/programs/preaching_training_img.jpg").default}
              />
              <br />
              <br />
              <p className="p-larger pb-2">
                Interested in the program?
                <br /><br />
                <a href="/#connect">Connect with us</a> for more information. This program is currently in the trial phase.
                <br /><br />
                When spots are available in the program, each candidate will need to be approved by the HGM Preaching Training Team.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
