/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Col, Container, Row } from "reactstrap"; // Button,

function Programs() {
  return (
    <>
      <div className="section pt-o section-renew-beige">
        <Container className="tim-container">
          <div id="programs" className="section section-renew-beige">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center">
                  <h1 className="title">Programs</h1>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center section-renew-beige" lg="6" md="8" sm="12">
                <a href = "/preaching_training"> 
                    <img
                      alt="Renew Logo"
                      className="img-rounded img-responsive section-renew-beige"
                      src={require("assets/img/programs/preaching_training.png").default}
                    />
                    <h1 className="quicksand pro_blue heavy no-margin">Preaching Training</h1>
                  </a>
                  <p className="pt-5">
                    A new training two-year training program to equip teachers of God's Word with workshops, coaching and practical talk experiences.
                  </p>
                </Col>
                <Col className="mr-auto ml-auto text-center" lg="6" md="8" sm="12">
                  <a href = "/speaker_reserve"> 
                    <img
                      alt="Recharge Logo"
                      className="img-rounded img-responsive"
                      src={require("assets/img/programs/speaker_reserve.png").default}
                    />
                    <h1 className="quicksand pro_green heavy no-margin">Speaker Reserve</h1>
                  </a>
                  <p className="pt-5">
                    Book a good gospel-focused speaker for your church service or event.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Programs;
