/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from "reactstrap";

const siteName = 'Speaker Reserve';
const description = 'Book a good gospel-focused speaker for your church service or event.';
var mainTitle = 'Speaker Reserve';

function SpeakerReserveHeader(props) {
  const { alternateTitle } = props;

  if (alternateTitle) {
    mainTitle = alternateTitle;
  }

  return (
    <>
      <Helmet>
        <title>{siteName}</title>
        <meta name='og:site_name' content={siteName} />
        <meta name='description' content={description} />
        <meta name='og:description' content={description} />
      </Helmet>
      <div className="page-header section section-speaker-reserve">
        <div className="content-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center">
                <div className="title-brand">
                  <img
                    alt="Speaker Reserve Logo"
                    className="img-rounded img-responsive renew-logo"
                    src={require("assets/img/programs/speaker_reserve_white.png").default}
                  ></img>
                </div>
                <h1 className="presentation-title">{mainTitle}</h1>
                <h2 className="presentation-subtitle text-center">
                  {description}
                </h2>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SpeakerReserveHeader;
