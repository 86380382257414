/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Button, Col, Container, Row } from "reactstrap";

function About() {
  return (
    <>
      <div id="about" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">About</h1>
              <p className="p-larger pb-2">
                HGM Speaker Reserve aims to equip churches with good gospel teaching by connecting with local speakers.
                <br /><br />
                Our local speakers are part of the HGM Preaching Training Program. Each participant attends training workshops, gains experience through giving talks in real ministry contexts, and receives feedback from peers and coaching from an experienced Bible teacher.
                <br /><br />
                You can see our preaching program details <a href="/preaching_training">here</a>.
                <br /><br />
                Participants are available to speak in a variety of contexts including church services, men's and women's events, youth groups, and outreach events.
              </p>
              <br /><br />
              <br /><br />

              <Button color="warning" size="lg" type="button" target="_blank" href="https://forms.gle/qtknmLtgUY7hoKHg6" rel="noreferrer" >
                Book a Speaker
              </Button>

              <br /><br />
              <br /><br />
              
              <img
                alt="Speaker Reserve"
                className="img-rounded img-responsive"
                src={require("assets/img/programs/speaker_reserve_img.jpg").default}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
