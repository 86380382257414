// import ReactPlayer from 'react-player';
import { Col, Container, Row } from "reactstrap"; // Button,

function CurrentConference() {

  return (
    <>
      <div id="currentConference" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Colossians</h1>

              <p className="p-larger pt-5 pb-2">
                The whole Bible is about Jesus, but in some books teaching about the person and the work of Christ come to a crescendo. Colossians is such a book. An understanding of Christ changes everything in the Christian's life: church, marriage, family, work, to name a few. 
              </p>
              <p className="p-larger pt-5 pb-2">
                On November 12th and 13th, we intend to give resources to Bible teachers to help in understanding the meaning of Colossians in its original context and to apply it faithfully to a world that needs to be reminded of the sufficiency of Christ.
              </p>


              {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/resource" rel="noreferrer" >
                Register Now
              </Button> */}

              <br />

              <p className="p-larger pt-5 pb-2">Our Speaker: Ian Smith</p>
              <img
                  alt="Mark Adams"
                  className="img-rounded img-responsive"
                  src={require("assets/img/resource_conference/profiles/Ian_Smith.png").default}
                />

              <p className="p-larger pt-5 pb-2">
                Ian Smith is married to Jenni and they have four adult children and seven grandchildren. Ian has been teaching New Testament at Christ College for 30 years, and has been the principal for 15 years. He preaches regularly at churches and Christian conferences. His doctoral research was on the book of Colossians and he is currently writing a commentary on Colossians/Philemon in the RETC series to be published by Crossway.
              </p>

              {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/resource" rel="noreferrer" >
                Register Now
              </Button> */}
              

              <p className="p-larger pt-5 pb-2">
                November 12th & 13th <br />
                Grace Evangelical Church<br/> 
                <a className="btn-link" href="https://www.google.com/maps/place/Grace+Evangelical+Church+Newcastle+Inc./@-32.928268,151.6407383,17z/data=!3m1!4b1!4m5!3m4!1s0x6b733c25d420430b:0x2b393a69344dbaf9!8m2!3d-32.9282725!4d151.642927" target="_blank" rel="noreferrer">344-348 Lake Rd, Glendale, NSW, 2285, Australia</a><br/>
                <br />
                8.30am Coffee & Registration <br/>
                9am - 3:45pm Wednesday <br/>
                9am - 3pm Thursday <br/>
              </p>

              <p className="p-larger pt-5 pb-2">
                <strong>What does God have to do with my ministry?</strong><br />
                Why the truth of God in himself (God's aseity) is foundational to Christian ministry.
              </p>
              
              <p className="p-larger pt-5 pb-2">
                <strong>Our Cultural and Ecclesial Contexts</strong><br />
                We live in a culture which is increasingly man centred. Our culture's man-centredness has affected the church. The church tends to focus almost exclusively on what God has done for us while giving little thought to who God is in himself. This has resulted in the church becoming 'theology-lite.' The truths of the gospel - incarnation, reconciliation, and redemption - are not thought through to the depths of God's own triune being and tend to be treated as exhausting all that can be said of God.
                This has had implications for the church's doctrine of God. We now question God's immutability, impassibility, and simplicity because these divine attributes don't fit simply with our notion of a God who relates to his people and world. Consequently, we think God is more like us than not. God's otherness, not simply in terms of his moral holiness, but the majesty of being, tends to be downplayed, or even worse, rarely mentioned.
              </p>

              <p className="p-larger pt-5 pb-2">
                <strong>So what?</strong><br />
                Surely, these ways of thinking impact the teaching and ministry of pastors and gospel workers. Here are some questions for you to consider:
                What priority do we as ministry practitioners give to reading big theology, especially the doctrine of God as Trinity? And what should I read?
                How much of my prayer life is devoted to delighting in and praising God for who he is?
                What place does (systematic) theology have in my preaching preparation, and how does it inform my exegetical preaching?
                Have I preached a series on God? How might I go about doing that?
              </p>

              {/* <Button color="warning" size="lg" type="button" target="_blank" href="https://www.eventcreate.com/e/resource" rel="noreferrer" >
                Register Now
              </Button> */}
            </Col> 
          </Row>
        </Container>
      </div>
</>)
};

export default CurrentConference;