import IndexFooter from "components/Footers/IndexFooter.js";
import React from "react";
import { Helmet } from 'react-helmet';
import About from "views/preaching-training-sections/About.js";
import PreachingTrainingHeader from '../components/Headers/PreachingTrainingHeader';
import PreachingTrainingNavbar from '../components/Navbars/PreachingTrainingNavbar';
import { domain, scheme } from './constants';

const title = 'Preaching Training';
const pageUrl = `${scheme}${domain}/preaching_training`;

function PreachingTraining() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <Helmet>
        <meta name='title' content={title} />
        <meta property="og:title" content={title} />
        <link rel='canonical' href={pageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <PreachingTrainingNavbar />
      <PreachingTrainingHeader />
      <div className="main">
        <About />
        <IndexFooter />
      </div>
    </>
  );
}

export default PreachingTraining;