import RechargeFooter from "components/Footers/RechargeFooter.js";
import React from "react";
import { Helmet } from 'react-helmet';
import About from "views/recharge-sections/About.js";
import Connect from "views/recharge-sections/Connect.js";
// import CurrentConference from "views/recharge-sections/CurrentConference";
import RechargeHeader from '../components/Headers/RechargeHeader';
import RechargeNavbar from '../components/Navbars/RechargeNavbar';
import { domain, scheme } from './constants';

const title = 'The Recharge Conference, for men in the Hunter Valley';
const pageUrl = `${scheme}${domain}/recharge`; 

function Recharge() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
      document.body.classList.add("index");
      return function cleanup() {
        document.body.classList.remove("index");
      };
    });
    return (
      <>
        <Helmet>
          <meta name='title' content={title} />
          <meta property="og:title" content={title} />
          <link rel='canonical' href={pageUrl} />
          <meta property="og:url" content={pageUrl} />
        </Helmet>
        <RechargeNavbar />
        <RechargeHeader />
        <div className="main">
          {/* <CurrentConference /> */}
          <About />          
          <Connect />
          <RechargeFooter />
        </div>
      </>
    );
  }
  
  export default Recharge;