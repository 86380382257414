/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "assets/demo/demo.css?v=1.3.0";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "bootstrap/scss/bootstrap.scss";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Index from "views/Index.js";
import Recharge from "views/recharge";
import RechargeTalks from "views/recharge-sections/talks/rechargeTalks";
import Renew from "views/renew";
import RenewTalks from "views/renew-sections/talks/renewTalks";
import Resource from "views/resource";
import PreachingTraining from "views/preaching-training";
import SpeakerReserve from "views/speaker-reserve";

ReactDOM.render(
  <BrowserRouter>
    <Switch>      
      <Route
        exact
        path="/renew/talks"
        render={(props) => <RenewTalks {...props} />}
      />
      <Route
        path="/renew"
        render={(props) => <Renew {...props} />}
      />
      <Route
        exact
        path="/recharge/talks"
        render={(props) => <RechargeTalks {...props} />}
      />
      <Route
        path="/recharge"
        render={(props) => <Recharge {...props} />}
      />
      <Route
        path="/resource"
        render={(props) => <Resource {...props} />}
      />
      <Route 
        path="/preaching_training" 
        render={(props) => <PreachingTraining {...props} />} 
      />
      <Route 
        path="/speaker_reserve" 
        render={(props) => <SpeakerReserve {...props} />} 
      />
      <Route 
        path="/" 
        render={(props) => <Index {...props} />} 
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
