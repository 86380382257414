/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Container, Row, Col } from "reactstrap";


function PastPresenters() {
  return (
    <>
      <div id="speakers" className="section section-resource-grey">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Past Presenters</h1>
              <p className="p-larger description pb-2 text-left">
                Over the years the following people have presented at the Resource Conference:
                <br /><br />
                <ul>
                  <li>Mark Adams (The Doctrine of God)</li>
                  <li>Michael Raiter (Preaching Evangelistically)</li>
                  <li>David Cook (Sermon preparation techniques)</li>    
                  <li>Peter Adam (Preaching that makes the OT sing)</li>            
                  <li>Graeme Goldsworthy (Preaching Biblical Theology)</li>
                  <li>Lindsay Wilson (preaching Wisdom Literature)</li>
                  <li>Andrew Cameron (Preaching Ethics)</li>
                  <li>Murray Capill (The preacher as pastor and the pastor as preacher)</li>
                  <li>Mike Raiter (Preaching Numbers)</li>
                  <li>Barry Webb (Song Of Songs &amp; Ruth)</li>
                  <li>David Starling (2 Corinthians)</li>
                  <li>Peter &amp; Christine Jensen</li>
                  <li>Greg Goswell (Joel &amp; Amos)</li>
                  <li>Michael Jensen (Bible + Culture)</li>
                  <li>Gary Miller (Zechariah)</li>
                  <li>Geoff Harper (Leviticus)</li>
                  <li>Dr Peter O'Brien</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PastPresenters;
