import IndexFooter from "components/Footers/IndexFooter.js";
import React from "react";
import { Helmet } from 'react-helmet';
import About from "views/speaker-reserve-sections/About.js";
import SpeakerReserveHeader from '../components/Headers/SpeakerReserveHeader';
import SpeakerReserveNavbar from '../components/Navbars/SpeakerReserveNavbar';
import { domain, scheme } from './constants';

const title = 'Speaker Reserve';
const pageUrl = `${scheme}${domain}/speaker_reserve`;

function SpeakerReserve() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <Helmet>
        <meta name='title' content={title} />
        <meta property="og:title" content={title} />
        <link rel='canonical' href={pageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      <SpeakerReserveNavbar />
      <SpeakerReserveHeader />
      <div className="main">
        <About />
        <IndexFooter />
      </div>
    </>
  );
}

export default SpeakerReserve;